import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import LandingPage from "./Components/Pages/landing";
import ResultPage from "./Components/Pages/results";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import Pentathlon from "./Components/Pentathlon/component";
import Biathlon from "./Components/Biathlon/component";
import Games from "./Components/Games/component";
import Leagues from "./Components/Leagues/component";
import EA from "./images/EasyAgility.png";
import { EmojiEvents, Home, Hub, Menu, Replay } from "@mui/icons-material";
import Champ from "./Components/Champ/component";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const App = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <BrowserRouter>
      <Box
        height={"100vh"}
        maxWidth={"100vw"}
        fontFamily={"Verdana"}
        textAlign="center"
        fontWeight={"normal"}
      >
        <Box
          top={0}
          color="white"
          textAlign={"left"}
          minHeight={"10vh"}
          bgcolor="#8f9c5d"
          position="fixed"
          minWidth={"100vw"}
          zIndex={1000}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "/";
                }}
                marginLeft={"2vw"}
                marginTop={1}
                display="inline-flex"
              >
                <img src={EA} alt="Easy Agility" style={{ height: "8vh" }} />
              </Box>
              <Box
                top="0"
                right="0"
                position="absolute"
                marginTop={1.5}
                marginRight={"2vw"}
              >
                <IconButton
                  onClick={() => {
                    setOpen(!open);
                  }}
                  sx={{ color: "#00395A" }}
                  size="large"
                >
                  <Menu />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          marginTop={10}
          minHeight="90vh"
          bgcolor="#cfd8e3"
          style={{ opacity: 1 }}
          left={0}
          right={0}
          padding={2}
        >
          {open ? (
            <Box padding={2}>
              <MenuList sx={{ textAlign: "left" }}>
                <MenuItem
                  onClick={() => setOpen(false)}
                  sx={{ border: 0.5, marginTop: 1 }}
                  to="/"
                  component={Link}
                >
                  <ListItemIcon>
                    <Home fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText>Home</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => setOpen(false)}
                  sx={{ border: 0.5, marginTop: 1 }}
                  to="/previous-shows"
                  component={Link}
                >
                  <ListItemIcon>
                    <Replay fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText>Previous Shows</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => setOpen(false)}
                  sx={{ border: 0.5, marginTop: 1 }}
                  to="/leagues/3"
                  component={Link}
                >
                  <ListItemIcon>
                    <EmojiEvents fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText>
                    JS Winter Steeplechase League 2024/25
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => setOpen(false)}
                  sx={{ border: 0.5, marginTop: 1 }}
                  to="/biathlon"
                  component={Link}
                >
                  <ListItemIcon>
                    <EmojiEvents fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText>Biathlon Overall Results</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => setOpen(false)}
                  sx={{ border: 0.5, marginTop: 1 }}
                  to="/games"
                  component={Link}
                >
                  <ListItemIcon>
                    <EmojiEvents fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText>Games Overall Results</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => setOpen(false)}
                  sx={{ border: 0.5, marginTop: 1 }}
                  to="/pentathlon"
                  component={Link}
                >
                  <ListItemIcon>
                    <EmojiEvents fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText>Pentathlon Overall Results</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    window.location.href = "https://hub.easyagility.co.uk";
                  }}
                  sx={{ border: 0.5, marginTop: 1 }}
                >
                  <ListItemIcon>
                    <Hub fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText>Easy Agility Hub</ListItemText>
                </MenuItem>
              </MenuList>
            </Box>
          ) : (
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/leagues/:leagueId" element={<Leagues />} />
              <Route path="/previous-shows" element={<ResultPage />} />
              <Route path="/champ" element={<Champ />} />
              <Route path="/pentathlon" element={<Pentathlon />} />
              <Route path="/biathlon" element={<Biathlon />} />
              <Route path="/games" element={<Games />} />
            </Routes>
          )}
          <Typography
            bgcolor={"white"}
            position="fixed"
            bottom={0}
            fontSize={12}
          >
            © Easy Agility 2024 - All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default App;
